import React from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/shared/SEO'
import styled from 'styled-components'
import HeaderSection from '../components/shared/modules/headerSection'
import ScrollMenu from '../components/shared/modules/scrollMenu'
import useRenderContentBlocks from '../hooks/useGetContentBlocks'
import { desktopBreakpoint } from '../styles'

const Wrapper = styled.section`
  .videoWithTextContainer + .imageAndTextSectionContainer {
    padding-top: 3px;
  }

  .section_block + .videoWithTextContainer {
    padding-top: 0;
  }

  .videoWithTextContainer + .gridSectionContainer {
    padding-top: 3px;
  }

  @media (min-width: ${desktopBreakpoint}) {
    margin: auto;

    .section_block + .videoWithTextContainer {
      padding-top: 17px;
    }

    .videoWithTextContainer + .gridSectionContainer {
      padding-top: 105px;
    }

    .circleSwiperContainer + .videoWithTextContainer {
      margin-top: 58px;
    }

    .imageAndTextSectionContainer + .imageAndTextSectionContainer {
      margin-top: 26px;
    }

    .imageAndTextSectionContainer:last-child {
      margin-bottom: 42px;
    }

    .videoWithTextContainer + .imageAndTextSectionContainer {
      padding-top: 105px;
    }
  }
`

const Modular = ({
  data: {
    contentfulModularPage: {
      heroImage,
      seoTitle,
      seoDescription,
      heroImageMobile,
      heroHeading,
      contentBlocks,
      description,
      title,
      headerType,
      heroSlides,
    },
  },
}) => {
  const headerTitles = []
  const headerIds = []

  contentBlocks &&
    contentBlocks.map(block =>
      block.makeHeaderMenu
        ? headerTitles.push(block.block.title) &&
          headerIds.push(
            block.block.title
              .toLowerCase()
              .split(' ')
              .join('_'),
          )
        : null,
    )

  return (
    <Wrapper>
      <SEO
        title={seoTitle}
        description={seoDescription}
        image={heroImage?.file.url}
        slug=''
      />

      <HeaderSection
        type={headerType}
        image={heroImage}
        imageMobile={headerType ? heroImageMobile || imageMobile : null}
        heading={heroHeading?.json}
        title={title}
        description={description}
        slides={heroSlides}
      />

      {headerTitles.length > 0 && (
        <ScrollMenu titles={headerTitles} ids={headerIds} />
      )}
      {contentBlocks && useRenderContentBlocks(contentBlocks)}
    </Wrapper>
  )
}

export const PageQuery = graphql`
  query Modular($id: String!) {
    contentfulModularPage(id: { eq: $id }) {
      seoTitle
      seoDescription
      slug
      title
      headerType
      heroSlides {
        title
        text
        buttonText
        image {
          fluid(maxWidth: 1024) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        imageMobile {
          fluid(maxWidth: 375) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        backgroundColor
        fontColor
        label
        article {
          ... on ContentfulArticle {
            slug
          }
          ... on ContentfulProduct {
            slug
          }
        }
      }
      heroImage {
        title
        file {
          url
        }
        fluid(maxWidth: 1280) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      heroImageMobile {
        title
        fluid(maxWidth: 375) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      heroHeading {
        json
      }
      description
      contentBlocks {
        id
        name
        type
        makeHeaderMenu

        block {
          ... on ContentfulComponentImageAndTextSection {
            title
            longText {
              json
            }
            buttonTarget {
              ... on Node {
                ... on ContentfulCollectionPage {
                  slug
                }
                ... on ContentfulLinkItem {
                  internalLink
                }
              }
            }
            buttonText
            image {
              fluid(maxWidth: 375) {
                ...GatsbyContentfulFluid_withWebp
              }
              title
            }
            addNewsletterField
            imagePlacement
          }
          ... on ContentfulComponentTwoQuotes {
            id
            name
            backgroundColors
            multiBlockPosition
            imageOrder
            firstQuoteAuthor
            firstQuoteText
            firstQuoteImage {
              fluid(maxWidth: 455) {
                ...GatsbyContentfulFluid_withWebp
              }
              title
            }
            secondQuoteText
            secondQuoteAuthor
            secondQuoteImage {
              fluid(maxWidth: 455) {
                ...GatsbyContentfulFluid_withWebp
              }
              title
            }
          }

          ... on ContentfulComponentVideoWithTitleAndText {
            id
            name
            backgroundColors
            title
            text
            videoLink
            small
          }
          ... on ContentfulComponentQuizz {
            quizzId
            popup
            height
            quizzTitle
            quizzSubtitle
            buttonText
          }
          ... on ContentfulComponentBanner {
            image {
              fluid(maxWidth: 1280) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            text
            buttonText
            backgroundColor
            fontColor
            article {
              ... on ContentfulArticle {
                slug
              }
              ... on ContentfulTextPage {
                slug
              }
            }
          }
          ... on ContentfulComponentGrid {
            title
            gridSubtitle {
              json
            }
            includeCardSubtitle
            includeButton
            people {
              slug
              jobFunction
              image {
                fluid(maxWidth: 265) {
                  ...GatsbyContentfulFluid_withWebp
                }
              }
              hoverImage {
                fluid(maxWidth: 265) {
                  ...GatsbyContentfulFluid_withWebp
                }
              }
              name
              shortSubtitle
            }
          }
          ... on ContentfulComponentCategorySwiper {
            id
            name
            title
            backgroundColors
            slidesMobile
            button {
              title
              link {
                ... on ContentfulCollectionPage {
                  slug
                }
                ... on ContentfulModularPage {
                  slug
                }
              }
              internalLink
            }
            slideCategories {
              ... on ContentfulCollectionPage {
                id
                name
                title
                slug
                thumbnail {
                  fluid(maxWidth: 265) {
                    ...GatsbyContentfulFluid_withWebp
                  }
                }
              }
              ... on ContentfulModularPage {
                id
                name
                title
                slug
                heroImage {
                  fluid(maxWidth: 500) {
                    ...GatsbyContentfulFluid_withWebp
                  }
                }
              }
            }
          }

          ... on ContentfulComponentEmailCapture {
            name
            backgroundColor
            id
            subtitle
            title
            placeholder
            buttonText
            buttonColor
            buttonBackground
            gdprText {
              json
            }
            gdpr
            thankYouMessage {
              json
            }
            listId
          }

          ... on ContentfulComponentCircleSwiper {
            id
            name
            title
            backgroundColors
            customTitles
            customSubtitles
            slideCategories {
              title
              internalLink
              link {
                ... on ContentfulCollectionPage {
                  slug
                }
                ... on ContentfulTextPage {
                  slug
                }
              }
            }
            icons {
              title
              fluid(maxWidth: 45) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }

          ... on ContentfulComponentStandardSwiper {
            id
            name
            title
            description
            multiBlockPosition
            useCampaignQuote
            button {
              link {
                ... on ContentfulCollectionPage {
                  slug
                }
                ... on ContentfulModularPage {
                  slug
                }
                ... on ContentfulTextPage {
                  slug
                }
              }
              externalLink
              title
            }
            images {
              title
              fluid(maxWidth: 265) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            slideCategories {
              ... on ContentfulUserReview {
                name
                title
                image {
                  fluid(maxWidth: 265) {
                    ...GatsbyContentfulFluid_withWebp
                  }
                }
                description {
                  description
                }
              }
              ... on ContentfulInstaPost {
                instagramUrl {
                  instagramUrl
                }
                instagramImage {
                  title
                  fluid(maxWidth: 265) {
                    ...GatsbyContentfulFluid_withWebp
                  }
                }
              }
              ... on ContentfulExpert {
                id
                name
                slug
                title
                jobFunction
                seoDescription
                seoTitle
                campaignQuote
                image {
                  fluid(maxWidth: 265) {
                    ...GatsbyContentfulFluid_withWebp
                  }
                }
              }
              ... on ContentfulMediaReview {
                id
                name
                slug
                review
                logo {
                  svg {
                    content
                  }
                }
                thumbnail {
                  fluid(maxWidth: 265) {
                    ...GatsbyContentfulFluid_withWebp
                  }
                }
              }
            }
          }

          ... on ContentfulComponentBulletsSwiper {
            id
            name
            title
            backgroundColor
            slideCategories {
              company
              review
              logo {
                svg {
                  content
                }
              }
            }
          }

          ... on ContentfulComponentSection {
            id
            name
            title
            description
            backgroundColors
            fullWidth
            limitVisibleArticles
            longDescription {
              json
            }
            button {
              title
              link {
                ... on ContentfulModularPage {
                  id
                  name
                  slug
                }
                ... on ContentfulCollectionPage {
                  id
                  name
                  slug
                }
                ... on ContentfulTextPage {
                  name
                  slug
                }
              }
            }
            cards {
              backgroundColor
              title
              largerFont
              description
              thumbnail {
                fluid(maxWidth: 200) {
                  ...GatsbyContentfulFluid_withWebp
                }
              }
              content {
                ... on ContentfulCollectionPage {
                  id
                  name
                  slug
                }
                ... on ContentfulModularPage {
                  id
                  name
                  slug
                }
              }
              externalLink
            }
            cardsVertical {
              ... on ContentfulArticle {
                id
                preview
                title
                slug
                image {
                  fluid(maxWidth: 600) {
                    ...GatsbyContentfulFluid_withWebp
                  }
                }
                label {
                  title
                  colorCode
                }
                content {
                  json
                }
              }
            }
          }
        }
      }
    }
  }
`
export default Modular
